@tailwind base;
@tailwind utilities;

@layer base {
  body {
    @apply text-gray-800 bg-white;
  }

  h1 {
    @apply font-primary;
  }

  .heading-1 {
    @apply text-[54px] lg:text-[108px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }

  .section {
    @apply min-h-screen;
  }

  .btn {
    @apply py-3 px-6 flex items-center justify-center text-base uppercase font-primary font-semibold text-center duration-200 shadow-md bg-black text-white;
  }
  
  .gradient-link {
    @apply bg-gradient-to-r from-gray-800 to-gray-700;
    @apply hover:from-gray-700 to-gray-800;
  }

}

.lazy-loaded-section {
  /* Set initial styles before animation */
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.lazy-loaded-section.in-viewport {
  /* Set final styles for when the element is in the viewport */
  opacity: 1;
  transform: translateY(0);
}

@layer utilities {
  @variants responsive {
    .masonry-4-col {
      column-count: 4;
      column-gap: 1em;
    }

    .masonry-2-col {
      column-count: 2;
      column-gap: 1em;
    }

    .break-inside {
      break-inside: avoid;
    }
  }

  .gradient-link {
    @apply inline-block relative overflow-hidden text-white font-medium border-2 border-gray-700;
    @apply transition-all duration-300 ease-in-out;
  }

  .gradient-link::before {
    content: '';
    @apply absolute inset-0 bg-gradient-to-r from-slate-900 to-slate-700 opacity-0;
    @apply transition-all duration-300 ease-in-out;
    @apply z-10;
  }

  .gradient-link:hover::before {
    @apply opacity-100;
  }

  .gradient-link:hover {
    @apply border-gray-800;
  }

  .gradient-link span {
    @apply relative z-20;
    @apply transition-all duration-300 ease-in-out;
  }

  .gradient-link:hover span {
    @apply text-opacity-0;
  }

  .gradient-link:hover span:hover {
    @apply text-opacity-100;
  }
}

html {
  scroll-behavior: smooth;
}